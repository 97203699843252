export const school_info = {
  telephone: "0254-216522",
  whatsapp: "085156593929",
  whatsapp_2: "081293158715",
  wa_me_1: "+6285156593929",
  wa_me_2: "+6281293158715",
  email: "adventusserang21@gmail.com",
  link_ppdb: "https://bit.ly/36erTA9",
  address:
    "Griya Baladika Asri Jl. Perjuangan RT. 002 RW. 015 Kelurahan Drangong Kecamatan Taktakan, Serang, Banten.",
  whatsapp_icon: "https://cdn-icons-png.flaticon.com/512/124/124034.png",
};

export const dummy_event_data = [
  {
    id: 1,
    title: "Love Forbidden (Défense d'aimer)",
    date: "22/03/2023",
    author: "Dimas Sanjaya",
    description:
      "Driver of military vehicle injured in traffic accident, subs Aliquip id reprehenderit fugiat elit eiusmod incididunt laboris quis consequat duis nisi deserunt exercitation sint. Deserunt nostrud eiusmod fugiat laboris laboris aute irure dolor adipisicing eiusmod culpa ex et irure. Elit aliquip ex adipisicing laborum laborum elit est labore. Amet aute incididunt dolor ipsum exercitation aliquip incididunt cupidatat nulla. Dolor laboris sit dolor ad. Deserunt est cupidatat ut sunt cupidatat sit. Qui cillum incididunt amet amet sit nostrud ut enim nulla sunt ipsum tempor.Deserunt non id incididunt do anim elit cupidatat enim.",
    image:
      "https://plus.unsplash.com/premium_photo-1677636665464-65bd76b8d3df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 2,
    title: "The Count of Monte Cristo",
    date: "22/03/2023",
    author: "Dimas Sanjaya",
    description: "Other secondary osteonecrosis, left humerus",
    image:
      "https://images.unsplash.com/photo-1679327970394-8593ba86023e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 3,
    title: "Paranoia Agent",
    date: "22/03/2023",
    author: "Dimas Sanjaya",
    description: "Maternal care for unstable lie, fetus 2",
    image:
      "https://images.unsplash.com/photo-1679231926688-ef9cdab5ed2f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 4,
    title: "Ringu (Ring)",
    date: "22/03/2023",
    author: "Dimas Sanjaya",
    description: "Oth fx upr end unsp rad, 7thH",
    image:
      "https://images.unsplash.com/photo-1679231926688-ef9cdab5ed2f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 5,
    title: "Chloe",
    description: "Fracture of sternal end of clavicle",
    image:
      "https://images.unsplash.com/photo-1679345506099-baca3b043efe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 6,
    title: "Margin Call",
    description: "Other scooter (nonmotorized) accident",
    image:
      "https://images.unsplash.com/photo-1679345506099-baca3b043efe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 7,
    title: "When Father Was Away on Business (Otac na sluzbenom putu)",
    description: "Unsp ultralt/microlt/pwr-glider acc inj occupant, sequela",
    image:
      "https://images.unsplash.com/photo-1679345506099-baca3b043efe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 8,
    title:
      "City of the Living Dead (a.k.a. Gates of Hell, The) (Paura nella città dei morti viventi)",
    description: "Sublux of proximal interphaln joint of r mid finger, sequela",
    image:
      "https://images.unsplash.com/photo-1679345506099-baca3b043efe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 9,
    title: "Requiem",
    description: "Rubber band causing external constriction, initial encounter",
    image:
      "https://images.unsplash.com/photo-1679345506099-baca3b043efe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 10,
    title: "Spring Break Shark Attack",
    description: "Displ longitud fx l patella, subs for clos fx w delay heal",
    image:
      "https://images.unsplash.com/photo-1679345506099-baca3b043efe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60",
  },
];

export const transportasi_option = [
  {
    label: "Mobil",
    value: "Mobil",
  },
  {
    label: "Motor",
    value: "Motor",
  },
  {
    label: "Sepeda",
    value: "Sepeda",
  },
  {
    label: "Jalan Kaki",
    value: "Jalan Kaki",
  },
];

export const jenis_kelamin_option = [
  {
    label: "Laki-Laki",
    value: "Laki-Laki",
  },
  {
    label: "Perempuan",
    value: "Perempuan",
  },
];
export const pekerjaan_option = [
  {
    label: "Anggota Polri",
    value: "Anggota Polri",
  },
  {
    label: "Anggota TNI",
    value: "Anggota TNI",
  },
  {
    label: "Buruh",
    value: "Buruh",
  },
  {
    label: "Karyawan Swasta",
    value: "Karyawan Swasta",
  },
  {
    label: "Petani",
    value: "Petani",
  },
  {
    label: "Guru",
    value: "Guru",
  },
  {
    label: "Wiraswasta",
    value: "Wiraswasta",
  },
];

export const pendidikan_option = [
  {
    label: "SD",
    value: "SD",
  },
  {
    label: "SMP/MTS",
    value: "SMP/MTS",
  },
  {
    label: "MA/SMA/SMK/STM",
    value: "MA/SMA/SMK/STM",
  },
  {
    label: "D1",
    value: "D1",
  },
  {
    label: "D2",
    value: "D2",
  },
  {
    label: "D3",
    value: "D3",
  },
  {
    label: "S1",
    value: "S1",
  },
  {
    label: "S2",
    value: "S2",
  },
  {
    label: "S3",
    value: "S3",
  },
  {
    label: "Tidak Tamat SD",
    value: "Tidak Tamat SD",
  },
];

export const facility_list = [
  "Computer Lab",
  "Science Lab",
  "English Club",
  "Pramuka",
  "Paskibra",
  "Music Course",
  "Basket",
  "Pathfinder Club",
  "Bible Study",
  "Ensamble",
  "Badminton",
];


export const event_data = [
  {
    id: 1,
    image: "10-hari-berdoa-program-konfrens-1.jpg",
    title: "10 Hari Berdoa Program Konfrens",
  },
  {
    id: 2,
    image: "10-hari-berdoa-program-konfrens-2.jpg",
    title: "10 Hari Berdoa Program Konfrens",
  },
  {
    id: 3,
    image: "hari-guru-1.jpg",
    title: "Hari Guru",
  },
  {
    id: 4,
    image: "hari-guru-2.jpg",
    title: "Hari Guru",
  },
  {
    id: 5,
    image: "kegiatan-tema-profesi-1.jpg",
    title: "Kegiatan Tema Profesi",
  },
  {
    id: 6,
    image: "kompetisi-1.jpg",
    title: " Juara 3 Lomba OSN Se-Kota Serang 2022",
  },
  {
    id: 7,
    image: "kompetisi-2.jpg",
    title: " Juara 3 Lomba OSN Se-Kota Serang 2022",
  },
  {
    id: 8,
    image: "kompetisi-3.jpg",
    title: "Kompetisi",
  },
  {
    id: 9,
    image: "kompetisi-4.jpg",
    title: "Partisipan Lomba Pembuatan Roket Se-Kota Serang",
  },
  {
    id: 10,
    image: "kumpul-guru-1.jpg",
    title: "Dewan Guru Yayasan Pendidikan Adventus Serang",
  },
  {
    id: 11,
    image: "lomba-montase-1.jpg",
    title: "Juara 3 Lomba Montase TK Se-Kota Serangse",
    style: {
      objectPosition: "0 -100px",
    },
  },
  {
    id: 12,
    image: "lomba-montase-2.jpg",
    title: "Juara 3 Lomba Montase TK Se-Kota Serang",
    style: {
      objectPosition: "0 -100px",
    },
  },
  {
    id: 13,
    image: "olimpiade-sains-nasional-1.jpg",
    title: "Olimpiade Sains Nasional",
  },
  {
    id: 14,
    image: "upacara-bendera-1.jpg",
    title: "Upacara Bendera Setiap Hari Senin",
  },
];
