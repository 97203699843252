import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { school_info } from "../utils/data";
import { media_query } from "../utils/theme";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

const MainLayout = () => {
  const navigate = useNavigate();
  return (
    <>
      <AppTitle>
        <AppLogo
          src={require("../assets/app-logo.png")}
          onClick={() => navigate("/")}
        />
        <SubTitle>YAYASAN PENDIDIKAN ADVENTUS SERANG</SubTitle>

        <NavigationBar />
      </AppTitle>
      <PageContainer>
        <Outlet />
      </PageContainer>
      <Footer />
      <FloatingActionButton
        onClick={() => window.open(`https://wa.me/${school_info.whatsapp}`)}
        src={school_info.whatsapp_icon}
      />
    </>
  );
};

export default MainLayout;

const FloatingActionButton = styled.img`
  background-color: gray;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  object-fit: cover;
  background-color: #1bd741;
  padding: 8px 8px 10px 10px;
  aspect-ratio: 2 / 1;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  &:hover {
    transition: 0.1s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: translateY(-10px);
  }
`;

const AppTitle = styled.div``;
const SubTitle = styled.h1`
  margin: 0;

  @media screen and (max-width: ${media_query.mini_mobile}) {
    font-size: 20px;
  }
`;

const PageContainer = styled.div`
  // padding: 0 40px;
  padding: 1.5rem 0;

  background-color: #ffffff;
  opacity: 0.8;
  background-image: radial-gradient(#8c75b9 0.45px, #ffffff 0.45px);
  background-size: 9px 9px;

  @media screen and (max-width: ${media_query.mobile}) {
    // margin: 10px 0;
    padding: 10px 0 10px 0;
  }
`;

const AppLogo = styled.img`
  border-radius: 9999px;
  width: 60px;
  height: 60px;
  margin-top: 10px;
`;
