import React from "react";
import { Container, Spacer, SubTitle } from "../utils/theme";

const Profile = () => {
  return (
    <Container>
      <Spacer />
      <SubTitle>Visi</SubTitle>
      <ul>
        <li>
          Berkarakter seperti Yesus Kristus, Berprestasi dalam Ilmu Pengetahuan
          dan Teknologi, serta Mandiri
        </li>
      </ul>
      <Spacer />
      <Spacer />
      <Spacer />
      <SubTitle>Misi</SubTitle>
      <ul>
        <li>Mewujudkan Pendidikan Advent yang beriman dan suka melayani</li>
        <li>Mengembangkan kemampuan murid agar terampil dalam ilmu</li>
        <li>Memotivasi murid agar Kreatif, Berprestasi dan Mandiri</li>
      </ul>
      <Spacer />
    </Container>
  );
};

export default Profile;
