import React from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./mainpage.scss";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { theme, Title, Spacer, Line, media_query } from "../utils/theme";
import { useMediaQuery } from "react-responsive";
import { event_data } from "../utils/data";
import LinesEllipsis from "react-lines-ellipsis";

const img_url = Array.apply(null, Array(5)).map((_, i) => i);
// const prestasi_img = Array.apply(null, Array(6)).map((_, i) => i);
// const kegiatan_img = Array.apply(null, Array(7)).map((_, i) => i);

const MainPage = () => {
  const isMiniTablet = useMediaQuery({
    query: `(min-width: ${media_query.mini_tablet})`,
  });
  return (
    <>
      <Container>
        {/* <Headline> */}
        <SwiperController
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          loop
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {img_url.map((source, index) => {
            return (
              <SwiperSlide style={{ position: "relative" }} key={index}>
                <Layer />

                <ImageContainer
                  src={require(`../assets/carousel-${source + 1}.jpg`)}
                  alt={`image-${index}`}
                />
              </SwiperSlide>
            );
          })}
        </SwiperController>
        {/* </Headline> */}
        {isMiniTablet && (
          <>
            <Spacer />
            <Spacer />
            <Line />
            <Spacer />
          </>
        )}
        <Title>Kegiatan & Prestasi</Title>
        <ItemContainer>
          {event_data.length > 0 &&
            event_data.map((prestasi) => {
              return (
                <Item key={prestasi.id}>
                  <ItemImage
                    src={require(`../assets-new/${prestasi.image}`)}
                    alt={prestasi.title}
                    loading="lazy"
                    style={prestasi.style}
                  />

                  <ItemInfo>
                    <ItemTitle text={prestasi.title} maxLine={2} />
                    {/* <LinesEllipsis text={prestasi.description} maxLine={3} /> */}
                  </ItemInfo>
                </Item>
              );
            })}
        </ItemContainer>
      </Container>
    </>
  );
};

const ItemTitle = styled(LinesEllipsis)`
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
`;

const ItemContainer = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-item: center;
  justify-items: start;
  margin: auto;

  @media screen and (max-width: ${media_query.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: ${media_query.mini_tablet}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: ${media_query.mini_mobile}) {
    grid-template-columns: 1fr;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ItemInfo = styled.div`
  padding: 10px 20px 10px 0;
  transition: 0.3s ease-in-out;
`;

const ItemImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 250px;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  object-position: top;
`;

const Item = styled.div`
  width: 100%;
  cursor: auto;
  transition: 0.3s ease-in-out;
  position: relative;

  &:hover {
    border-radius: 10px;
    background-color: ${theme.white};
    // padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  &:hover ${ItemImage} {
    transition: 0.3s ease-in-out;
    border-radius: 10px 10px 0 0;
  }
  &:hover ${ItemInfo} {
    transition: 0.3s ease-in-out;
    padding: 10px;
  }
`;

const Container = styled.div`
  padding: 0.5rem 2rem;
  text-align: start;

  @media screen and (max-width: ${media_query.tablet}) {
    padding: 0 1rem;
  }
`;

const SwiperController = styled(Swiper)`
  // border: 5px solid ${theme.primary_color};
  border-radius: 10px;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: ${media_query.mini_tablet}) {
    margin-bottom: 1rem;
  }
`;
const ImageContainer = styled.img`
  // background-image: ${(props) => props.src}

  height: 520px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;

  // border-bottom: 5px solid ${theme.primary_color};
  @media screen and (max-width: ${media_query.tablet}) {
    // margin-bottom: 1rem;
    height: 420px;
  }

  @media screen and (max-width: ${media_query.mobile}) {
    // margin-bottom: 1rem;
    height: 220px;
  }
`;

const Layer = styled.div`
  background-color: black;
  // background-color: ${theme.secondary_color};
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;
export default MainPage;
