import React from "react";
import "./scrolling-text.scss";

const ScrollingText = ({ text }) => {
  return (
    <div id="scroll-container">
      <div id="scroll-text">{text}</div>
    </div>
  );
};

export default ScrollingText;
