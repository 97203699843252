import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import router_list from "../router/route_list";
import { media_query, theme } from "../utils/theme";
import Breadcrumb from "./Breadcrumb";

const NavigationBar = () => {
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    navigate(route.path);
  };

  return (
    <Container>
      <NavigationMenu>
        {router_list[0].children.map((route) => {
          const isActive = window.location.pathname
            .toLowerCase()
            .includes(route.id);
          return (
            !route.id.includes("detail") && (
              <NavigationLink
                isActive={isActive}
                key={route.id}
                onClick={() => handleNavigate(route)}
              >
                {String(route.name).toUpperCase()}
              </NavigationLink>
            )
          );
        })}
      </NavigationMenu>
      <StyledBreadcrumb />
    </Container>
  );
};

const Container = styled.div`
  transition: background-color 1s ease;
  width: 100%;
  text-align: start;
  // position: sticky;
  // top: 0;
  z-index: 999;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
// box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
"box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;"};
`;

export const NavigationLink = styled.a`
  cursor: pointer;
  color: ${(props) =>
    props.isActive ? theme.third_color : theme.primary_color};
  &:hover {
    color: ${(props) =>
      props.isActive ? theme.third_color : theme.secondary_color} !important;
    // text-decoration: underline;
  }

  @media screen and (max-width: ${media_query.mini_mobile}) {
    font-size: 12px;
  }
`;


export const NavigationMenu = styled.div`
  // background-color: ${theme.white};
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 1rem 0;
  font-size: 1rem;
  flex-wrap: wrap;

  @media screen and (max-width: ${media_query.mobile}) {
    gap: 1rem;
  }
`;

export default NavigationBar;
