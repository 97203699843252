import React from "react";
import styled from "styled-components";
import { Group, StyledLink } from "../pages/Contact";
import { Section } from "../pages/PPDB";
import { school_info } from "../utils/data";
import {
  media_query,
  UnderlineTitle,
  Text,
  theme,
  SubTitle,
  StyledButton,
} from "../utils/theme";

const Footer = () => {
  return (
    <>
      <Container>
        <SchoolSection>
          <SubTitle>YAYASAN PENDIDIKAN ADVENTUS SERANG</SubTitle>
          <Text></Text>
        </SchoolSection>
        <ContactSection>
          <Group>
            <SubTitle>Informasi Kontak</SubTitle>
            <UnderlineTitle>Alamat Lengkap</UnderlineTitle>
            <span>{school_info.address}</span>
          </Group>
          <Section>
            <Group>
              <UnderlineTitle>Kontak</UnderlineTitle>
              <Text>{school_info.telephone} (Telepon)</Text>
              <Text>{school_info.wa_me_1} (Whatsapp)</Text>
              <Text>{school_info.wa_me_2} (Whatsapp)</Text>
            </Group>
            <Group>
              <UnderlineTitle>Email</UnderlineTitle>
              <Text>{school_info.email}</Text>
            </Group>
          </Section>
        </ContactSection>
        <EmailSection>
          <StyledButton onClick={() => window.open("https://bit.ly/36erTA9")}>
            Daftar Sekarang
          </StyledButton>
        </EmailSection>
      </Container>
      <div
        style={{
          backgroundColor: theme.darker_primary_color,
          padding: "10px",
          color: "white",
        }}
      >
        {/* <NavigationMenu>
          {router_list[0].children.map((route, index) => {
            const isActive = window.location.pathname
              .toLowerCase()
              .includes(route.id);
            return (
              !route.id.includes("detail") && (
                <>
                  {index > 0 && "| "}
                  <NavigationLink
                    style={{
                      color: isActive
                        ? theme.third_color
                        : theme.secondary_color,
                    }}
                    key={route.id}
                    onClick={() => navigate(route.path)}
                  >
                    {route.name}
                  </NavigationLink>
                </>
              )
            );
          })}
        </NavigationMenu>s */}
        Made by @{" "}
        <StyledLink
          target="_blank"
          style={{ color: theme.white }}
          href="http://bilcode.id"
        >
          Bilcode.id
        </StyledLink>
      </div>
    </>
  );
};

const SchoolSection = styled.div``;
const EmailSection = styled.div`
  @media screen and (max-width: ${media_query.mini_tablet}) {
    display: none;
  }
`;
const ContactSection = styled.div`
  @media screen and (max-width: ${media_query.mobile}) {
    margin-top: 1rem;
  }
`;

const Container = styled.div`
  gap: 5rem;
  text-align: start;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  width: 100%;
  padding: 40px;
  background-color: ${theme.primary_color};
  color: ${theme.white};

  @media screen and (max-width: ${media_query.mini_tablet}) {
    grid-template-columns: 1fr 1fr;
    gap: 3em;
  }
  @media screen and (max-width: ${media_query.mobile}) {
    display: block;
    gap: 3rem;
  }
`;

export default Footer;
