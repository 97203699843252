import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dummy_event_data } from "../utils/data";
import { useQuery } from "../utils/utils";
import styled from "styled-components";
import {
  Container,
  media_query,
  Spacer,
  StyledSmallButton,
  Text,
} from "../utils/theme";

const EventDetail = () => {
  const query = useQuery();
  const [data, setData] = useState(null);
  const { name } = useParams();

  useEffect(() => {
    setData(
      dummy_event_data.filter(
        (event) => Number(event.id) === Number(query.get("id"))
      )[0]
    );
  }, [name, query]);
  return (
    <StyledContainer>
      <Image src={data?.image} alt="event-image" />
      <div style={{ flex: 1 }}>
        <EventDetailTitle>{data?.title}</EventDetailTitle>
        <Text>{data?.description}</Text>

        {data?.date && (
          <>
            <Spacer />
            <Spacer />
            <Section>
              <Section style={{ gap: "5px" }}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    width={15}
                    height={15}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                    />
                  </svg>
                </span>
                <Text>{data?.date}</Text>
              </Section>
              <Section style={{ gap: "5px", flexWrap: "nowrap" }}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    width={15}
                    height={15}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>
                </span>
                <Text>{data?.author}</Text>
              </Section>
            </Section>
          </>
        )}

        <Spacer />
        <StyledSmallButton>Ikuti Acara</StyledSmallButton>
      </div>
    </StyledContainer>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const EventDetailTitle = styled.h1`
  margin: 0 0 10px 0;

  @media screen and (max-width: ${media_query.mobile}) {
    display: block;
    margin-top: 10px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  gap: 2rem;

  @media screen and (max-width: ${media_query.mobile}) {
    display: block;
  }
`;

const Image = styled.img`
  object-fit: cover;
  border-radius: 10px;
  width: 300px;
  height: 400px;

  @media screen and (max-width: ${media_query.mobile}) {
    display: block;
    width: 100%;
    height: 400px;
  }

  @media screen and (max-width: ${media_query.mini_mobile}) {
    height: 200px;
  }
`;

export default EventDetail;
