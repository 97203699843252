import MainLayout from "../components/MainLayout";
import Contact from "../pages/Contact";
import Event from "../pages/Event";
import EventDetail from "../pages/EventDetail";
import MainPage from "../pages/MainPage";
import PPDB from "../pages/PPDB";
import Profile from "../pages/Profile";

const router_list = [
  {
    id: "main_layout",
    name: "Layout",
    element: <MainLayout />,
    children: [
      {
        id: "main_page",
        name: "Beranda",
        path: "/",
        element: <MainPage />,
      },
      {
        id: "profile",
        name: "Profile",
        path: "/profile",
        element: <Profile />,
      },
      {
        id: "event-detail",
        name: "Event Detail",
        path: "/event/:name",
        element: <EventDetail />,
      },
      {
        id: "event",
        name: "Event",
        path: "/event",
        element: <Event />,
      },
      {
        id: "contact",
        name: "Contact",
        path: "/contact",
        element: <Contact />,
      },
      {
        id: "ppdb",
        name: "PPDB",
        path: "/ppdb",
        element: <PPDB />,
      },
    ],
  },
];

export default router_list;
