import React from "react";
import styled from "styled-components";
import { media_query, theme } from "../utils/theme";
import { capitalize } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import ScrollingText from "./ScrollingText";
import { school_info } from "../utils/data";

let prompt_text_list = [
  "YAYASAN PENDIDIKAN ADVENTUS SERANG",
  "TK - SD - SMP",
  `📞 ${school_info.telephone}`,
  `WA: ${school_info.wa_me_1}`,
  `${school_info.wa_me_2}`,
  "Alamat: Griya Baladika Asri Jl. Perjuangan RT. 002 RW. 015 Kelurahan Drangong Kecamatan Taktakan, Serang, Banten.",
];

prompt_text_list = prompt_text_list.join("* | *").split("*");

const Breadcrumb = () => {
  const URLS = window.location.pathname.split("/");
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <BreadcrumbContainer>
          {URLS.length > 0
            ? URLS.map((url, index) => {
                let name = url;

                if (name === "ppdb") {
                  name = "Pendaftaran Peserta Didik Baru";
                }

                const parsedName = capitalize(String(name));
                const parsedUrl = capitalize(String(url));

                const isActive =
                  parsedUrl === capitalize(URLS[URLS.length - 1]);
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    {isActive && parsedName !== "" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="white"
                        width={15}
                        height={15}
                        style={{ marginRight: "5px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                        />
                      </svg>
                    )}
                    <URLLink
                      key={index}
                      onClick={() => !isActive && navigate(url)}
                      style={{
                        color:
                          URLS.length > 0 && isActive
                            ? theme.white
                            : theme.opacity_white,
                      }}
                    >
                      {parsedName}
                    </URLLink>

                    {index !== URLS.length - 1 && index !== 0 && (
                      <span style={{ color: "white", margin: "0 5px" }}>
                        {" "}
                        /{" "}
                      </span>
                    )}
                  </div>
                );
              })
            : null}
        </BreadcrumbContainer>
        <InfoContainer>
          <ScrollingText
            text={
              <TextContent>
                {prompt_text_list.map((text, index) => {
                  return <Text key={index}>{text}</Text>;
                })}
              </TextContent>
            }
          />
        </InfoContainer>
      </Container>
    </>
  );
};

const Text = styled.span`
  white-space: nowrap;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
`;

const Container = styled.div`
  overflow: hidden;
  display: flex;
  padding: 10px 15px;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  //   margin-bottom: 1.5rem;
  background-color: ${theme.primary_color};

  @media screen and (max-width: ${media_query.mobile}) {
    display: block;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: ${theme.white};

  @media screen and (max-width: ${media_query.mobile}) {
    margin-top: 0.5rem;
  }
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  //   gap: 10px;
`;

const URLLink = styled.div`
  margin-right: 2px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
    color: ${theme.white} !important;
  }

  @media screen and (max-width: ${media_query.mobile}) {
    white-space: normal;
  }
`;

export default Breadcrumb;
