import React from "react";
import styled from "styled-components";
import { facility_list, school_info } from "../utils/data";
import {
  Container,
  media_query,
  Spacer,
  StyledSmallButton,
  theme,
} from "../utils/theme";

import "./ppdb.scss";

const PPDB = () => {
  return (
    <Container>
      <div className="c-subscribe-box u-align-center">
        <div className="rainbow">
          <span></span>
          <span></span>
        </div>
        <div className="c-subscribe-box__wrapper">
          <Spacer />
          <Spacer />
          <h1 className="c-subscribe-box__title">
            Pendaftaran Peserta Didik Baru
          </h1>
          <p className="c-subscribe-box__desc">
            Ayo! Daftarkan dirimu segera. Dapatkan penawaran dengan cara klik
            tombol dibawah ini sekarang!
          </p>
          <Section style={{ width: "100%", justifyContent: "center" }}>
            <StyledSmallButton
              onClick={() =>
                window.open(`https://wa.me/${school_info.wa_me_1}`)
              }
              style={{
                width: "250px",
                backgroundColor: theme.third_color,
                border: `1px solid ${theme.third_color}`,
                marginBottom: "1rem",
              }}
            >
              Dapatkan Penawaran
            </StyledSmallButton>

            {/* <Spacer /> */}
            <StyledSmallButton
              onClick={() => window.open(school_info.link_ppdb)}
              style={{
                width: "250px",
                marginBottom: "1rem",
              }}
            >
              Daftar Sekarang
            </StyledSmallButton>
          </Section>
          <Spacer />
          <Spacer />
          <Spacer />
          <h1 className="c-subscribe-box__title">Fasilitas & Aktivitas</h1>
          <p className="c-subscribe-box__desc">
            Adventus menyediakan berbagai macam fasilitas dan aktivitas untuk
            mendukung hobi dan cita-cita anak anda.
          </p>
          <FacilityContainer>
            {facility_list.map((facility, index) => {
              return <FacilityItem key={index}>{facility}</FacilityItem>;
            })}
          </FacilityContainer>
        </div>
      </div>
    </Container>
  );
};

const FacilityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 70%;
  justify-content: center;
`;

const FacilityItem = styled.div`
  padding: 5px 10px;
  border-radius: 999px;
  background-color: ${theme.primary_color};
  // border: 2px solid ${theme.secondary_color};
  color: ${theme.white};
`;

export const Section = styled.div`
  display: flex;
  width: 100%;
  gap: 3rem;
  flex-direction: row;
  align-item: center;
  @media screen and (max-width: ${media_query.tablet}) {
    display: block;
  }
`;

export default PPDB;
