import React from "react";
import styled from "styled-components";
import {
  media_query,
  UnderlineTitle,
  Text,
  SubTitle,
  StyledSmallButton,
} from "../utils/theme";
import { school_info } from "../utils/data";

const Contact = () => {
  return (
    <>
      <Container>
        <InfoContainer>
          <SubTitle>Informasi Sekolah</SubTitle>
          <Group>
            <UnderlineTitle>Alamat Lengkap</UnderlineTitle>
            <span>{school_info.address}</span>
          </Group>
          <Group>
            <UnderlineTitle>Kontak</UnderlineTitle>
            <Text >{school_info.telephone} (Telepon)</Text>
            <Text onClick={() => {window.open(`https://wa.me/${school_info.wa_me_1}`)}}>{school_info.wa_me_1} (Whatsapp)</Text>
            <Text onClick={() => {window.open(`https://wa.me/${school_info.wa_me_2}`)}}>{school_info.wa_me_2} (Whatsapp)</Text>
          </Group>
          <Group>
            <UnderlineTitle>Email</UnderlineTitle>
            <StyledLink href={`mailto:${school_info.email}`}>{school_info.email}</StyledLink>
          </Group>
          <Group>
            <UnderlineTitle>PPDB</UnderlineTitle>
            <StyledSmallButton
              onClick={() => window.open(school_info.link_ppdb)}
            >
              Daftar Sekarang
            </StyledSmallButton>
          </Group>
          {/* <Group>
            <UnderlineTitle>Media Sosial</UnderlineTitle>
            <SocialMediaIcons
              src="https://cdn-icons-png.flaticon.com/128/2111/2111463.png"
              alt="instagram"
              width={20}
              height={20}
              style={{ marginRight: 10 }}
            />
            <SocialMediaIcons
              src="https://cdn-icons-png.flaticon.com/128/733/733547.png"
              alt="instagram"
              width={20}
              height={20}
            />
          </Group> */}
        </InfoContainer>
        <Maps
          title="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63476.565975021906!2d106.094671611431!3d-6.092207299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e418bccc0a8a717%3A0x33022e50ba1d294a!2sYayasan%20Pendidikan%20Adventus%20Serang!5e0!3m2!1sid!2sid!4v1679467807824!5m2!1sid!2sid"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </Container>
    </>
  );
};

export const StyledLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 13px;
`;

const Container = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 40% 50%;
  width: 70%;
  margin: auto;

  @media screen and (max-width: ${media_query.tablet}) {
    display: block;
  }

  @media screen and (max-width: ${media_query.tablet}) {
    width: 80%;
  }
  @media screen and (max-width: ${media_query.mini_tablet}) {
    width: 90%;
  }

  @media screen and (max-width: ${media_query.mobile}) {
    width: 100%;
  }
`;

// const SocialMediaIcons = styled.img`
//   width: 20px;
//   height: 20px;
//   margin-right: 10px;
//   cursor: pointer;
// `;

export const Group = styled.div`
  margin-bottom: 1rem;
`;

const InfoContainer = styled.div`
  border-radius: 10px;
  background-color: white;
  // min-height: 78vh;
  min-width: 300px;
  margin-left: auto;
  flex: 1;
  text-align: start;
  padding: 2rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

const Maps = styled.iframe`
  width: 100%;
  border-radius: 10px;
  border: none;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    @media screen and (max-width: ${media_query.tablet}) {
      margin-top: 2rem};
      // border-radius: 10px 10px 0 0
    }
`;

export default Contact;
