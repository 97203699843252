import styled from "styled-components";

export const media_query = {
  desktop: "1440px",
  mini_desktop: "1156px",
  tablet: "992px",
  mini_tablet: "734px",
  mobile: "553px",
  mini_mobile: "442px",
};

export const theme = {
  primary_color: "#65577f",
  secondary_color: "#BD99FF",
  darker_primary_color: "#52456A",
  third_color: "#4fbda0",
  white: "#ffffff",
  opacity_white: "rgba(255,255,255, 0.6)",
};

export const Container = styled.div`
  margin: auto;
  width: 70%;
  text-align: start;

  @media screen and (max-width: ${media_query.tablet}) {
    width: 80%;
  }
  @media screen and (max-width: ${media_query.mini_tablet}) {
    width: 90%;
  }

  @media screen and (max-width: ${media_query.mobile}) {
    width: 100%;
    padding: 0 10px;
  }
`;

export const StyledInput = styled.input`
  margin-bottom: 30px;
  border-radius: 999px;
  padding: 20px 30px;
  color: black;
  width: -webkit-fill-available;
  font-size: 1rem;
  border: 3px solid ${theme.primary_color};

  &:focus {
    border: 3px solid ${theme.third_color};
  }

  @media screen and (max-width: ${media_query.mini_desktop}) {
    padding: 15px 25px;
  }
  @media screen and (max-width: ${media_query.tablet}) {
    padding: 10px 15px;
  }
  @media screen and (max-width: ${media_query.mobile}) {
    margin-bottom: 15px;
  }
`;


export const Title = styled.h1`
  margin: 0 0 20px 0;
`;

export const SubTitle = styled.h2`
  margin: 0 0 20px 0;
`;

export const UnderlineTitle = styled.p`
  text-decoration: underline;
  margin: 0 0 10px 0;
  font-size: 14px;
`;

export const Spacer = styled.div`
  width: 100%;
  height: 1rem;
  background: transparent;
`;

export const Text = styled.p`
  margin: 0;
`;

export const StyledButton = styled.button`
  border-radius: 999px;
  padding: 20px 30px;
  width: -webkit-fill-available;
  font-size: 1.2rem;
  border: 3px solid ${theme.secondary_color};
  cursor: pointer;
  background: ${theme.primary_color};
  color: ${theme.white};

  &:hover {
    cursor: pointer;
    background: ${theme.third_color};
    border: 3px solid transparent;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    // color: ${theme.secondary_color};
    // border: 3px solid ${theme.primary_color};
  }

  &:active {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  @media screen and (max-width: ${media_query.tablet}) {
    padding: 10px 15px;
  }
`;

export const StyledSmallButton = styled.button`
  border-radius: 999px;
  padding: 10px 20px;
  width: -webkit-fill-available;
  font-size: 1.2rem;
  border: 3px solid ${theme.secondary_color};
  cursor: pointer;
  background: ${theme.primary_color};
  color: ${theme.white};

  &:hover {
    cursor: pointer;
    background: ${theme.third_color};
    border: 3px solid transparent;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    // font-weight: bold;
    // border: 3px solid ${theme.primary_color};
  }

  &:active {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
`;

export const Line = styled.hr`
  border-color: ${theme.primary_color};
`;
