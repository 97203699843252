import React, { useState } from "react";
import { event_data } from "../utils/data";
import styled from "styled-components";

import "./ppdb.scss";
import { Container, media_query, StyledInput, theme } from "../utils/theme";
import LinesEllipsis from "react-lines-ellipsis";

const Event = () => {
  const [searchController, setSearchController] = useState("");
  const [data, setData] = useState(event_data);

  const handleSearch = (e) => {
    setSearchController(e.target.value);
    setData(
      event_data.filter((item) =>
        item.title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const EventContainer = styled.div`
    display: grid;
    row-gap: 30px;
    grid-template-columns: ${data.length > 0 ? "1fr 1fr 1fr " : "100%"};
    align-item: center;
    justify-items: ${data.length > 0 ? "start" : "center"};
    // max-height: 75vh;
    overflow-y: auto;
    margin: auto;

    @media screen and (max-width: ${media_query.tablet}) {
      grid-template-columns: ${data.length > 0 ? "1fr 1fr" : "100%"};
    }
    @media screen and (max-width: ${media_query.mini_mobile}) {
      grid-template-columns: ${data.length > 0 ? "1fr" : "100%"};
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `;

  // const navigate = useNavigate();

  // const toggle = (data) => {
  //   navigate(`/event/${data.title}?id=${data.id}`);
  // };

  return (
    <>
      <Container>
        <StyledInput
          value={searchController}
          onChange={handleSearch}
          placeholder="Search Event..."
        />
        <EventContainer>
          {data.length > 0 ? (
            data.map((item) => {
              return (
                <EventItem
                  key={item.id}
                  // onClick={() => toggle(item)}
                >
                  <EventImage
                    src={require(`../assets-new/${item.image}`)}
                    alt={item.title}
                    loading="lazy"
                    style={item.style}
                  />
                  <EventInfoContainer>
                    <EventTitle text={item.title} maxLine={2} />
                    {/* <LinesEllipsis text={item.description} maxLine={3} /> */}
                  </EventInfoContainer>
                </EventItem>
              );
            })
          ) : (
            <NoDataFoundContainer>
              <img
                src={
                  "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?w=2000&t=st=1679407818~exp=1679408418~hmac=05b89d8eb7781e3a119d4388adfa56663dc643bf696146d3b067e597b9f4eca2"
                }
                alt="no-data-found"
                width={300}
                height={200}
              />
              <h2>Oops!</h2>
              <StyledParagraph>
                No data found{" "}
                {searchController !== ""
                  ? `with keywords "${searchController}"`
                  : ""}
              </StyledParagraph>
            </NoDataFoundContainer>
          )}
        </EventContainer>
      </Container>
    </>
  );
};

const NoDataFoundContainer = styled.div`
  text-align: center;
  overflow-wrap: break-word;
  white-space: normal;
`;

const EventItem = styled.div`
  width: 93%;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media screen and (max-width: ${media_query.mini_mobile}) {
    width: 100%;
  }
`;

const EventInfoContainer = styled.div`
  text-align: start;
`;

const EventImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  object-position: top;
`;

const EventTitle = styled(LinesEllipsis)`
  font-size: 1rem;
  margin: 10px 0;
  font-weight: bold;
  // min-height: 50px;
  &:hover {
    text-decoration: underline;
    color: ${theme.primary_color};
  }
`;

const StyledParagraph = styled.p`
  text-align: center;
  overflow-wrap: break-word;
  white-space: normal;
`;

export default Event;
